import React from "react";
import Layout from "../components/common/Layout";

function Terms() {
  return (
    <Layout>
      <section className="residence-banner relative">
        <div className="d-flex align-items-center abs-full tint">
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h2 className="banner-title">Terms &amp; Conditions</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="residence-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="policy-content text-justify">
                <h2>Take a break : Legal Knowledge</h2>
                <p>
                  Owning a house, is an investment and a dream come true. It is
                  a remarkable milestone for many families. Given the
                  significance of investing in a home there are certain facts
                  that needs to be checked before committing your investment. We
                  as responsible builders would like to present a few guidelines
                  that will be of help to become a buyer.
                </p>
                <h5>First Things First</h5>
                <ul>
                  <li>
                    &nbsp;The legal status of the land should be confirmed above
                    anything.
                  </li>
                  <li>
                    {" "}
                    &nbsp;Advance should be made only after confirmation of
                    legal documents.{" "}
                  </li>
                </ul>
                <p />
                <h5>Before Buying a Land</h5>
                <ul>
                  <li>&nbsp;Check for clear marketable title.</li>
                  <li>
                    &nbsp;Find out the tenure or possession right.
                    (Freehold/Leasehold/Grant or Sanad)
                  </li>
                  <li>Note: Freehold is always preferred.</li>
                  <li>
                    &nbsp;Legal right of the holder of the land in Govt Records.
                    The seller should provide these documents to the buyer.
                  </li>
                </ul>
                <h5>Title Deeds</h5>
                <ul>
                  <li>
                    &nbsp;Confirm that land is in the name of the seller The
                    right to sell the land lies with only the seller and not
                    anyone else.
                  </li>
                  <li>
                    &nbsp;Always request for the original deed. Don’t entertain
                    photocopies.
                  </li>
                  <li>&nbsp;Possible option : Original can be pledged.</li>
                  <li>&nbsp;Have a lawyer approve the original.</li>
                  <li>
                    &nbsp;Check for any suppressed or undisclosed facts in the
                    document.
                  </li>
                  <li>&nbsp;Previous deeds of land can also be verified.</li>
                </ul>
                <h5>Tax Receipt and Bills</h5>
                <ul>
                  <li>
                    &nbsp;Property taxes due to government and municipality are
                    made with proof of bills and receipts.
                  </li>
                  <li>&nbsp;Up-to-date tax bills need to be checked.</li>
                  <li>
                    Check for columns containing.
                    <ol>
                      <li>Owners Name</li>
                      <li>
                        Tax Payers Name Sometimes the owner may not have the tax
                        receipt, In case we need to contact the village office
                        with the survey number of the land and confirm the
                        original owner of the land.
                      </li>
                    </ol>
                  </li>
                  <li>
                    &nbsp;If buying a house with a property, then house tax
                    receipt should be checked.
                  </li>
                  <li>
                    &nbsp;Check for the water tax bill and electricity bill. If
                    any balance request the seller to get it paid.
                  </li>
                </ul>
                <h5>Encumbrance Certificate (EC)</h5>
                <ul>
                  <li>
                    &nbsp;Confirm that the land doesn’t have any legal dues.
                  </li>
                  <li>
                    &nbsp;The encumbrance certificate can be obtained from the
                    sub registrar office where the deed is registered.
                  </li>
                  <li>
                    &nbsp;The EC should state that the said land doesn’t have
                    any legal dues and complaints.
                  </li>
                  <li>
                    &nbsp;The EC must be obtained for 13 years and for more
                    clarification we can demand 30 years certificate also.
                  </li>
                  <li>
                    &nbsp;In case of any doubts we can take possession
                    certificate of the ownership of the particular land from the
                    village office.
                  </li>
                </ul>
                <h5>Pledged Land</h5>
                <ul>
                  <li>
                    &nbsp;When buying a pledged land ensure that the seller has
                    paid back all the amounts due.
                  </li>
                  <li>
                    &nbsp;Do request for the release certificate from the bank,
                    to ensure that the debts are cleared.
                  </li>
                  <li>
                    &nbsp;Land can be bought without the release certificate,
                    but to take a loan in future may not be possible without it.
                  </li>
                  <li>
                    &nbsp;If the land is owned by more than one owner, get RC
                    from the other owners involved.
                  </li>
                </ul>
                <h5>Measuring a Land</h5>
                <ul>
                  <li>
                    &nbsp;It is advisable to measure the land before registering
                    the land in your name.
                  </li>
                  <li>
                    &nbsp;Ensure that the measurements of the plot and its
                    borders are accurate using a recognized surveyor.
                  </li>
                  <li>
                    &nbsp;Hold a survey sketch of the land from the survey
                    department and compare for accuracy.
                  </li>
                </ul>
                <h5>Buying land from NRI land owners</h5>
                <ul>
                  <li>
                    &nbsp;An NRI can sell his land in India by giving Power of
                    Attorney to a third person authorizing the right to sell the
                    land on his behalf.
                  </li>
                  <li>
                    &nbsp;In cases like this the Power of Attorney should be
                    witnessed and duly signed by an officer in the Indian
                    embassy in his province.
                  </li>
                  <li>
                    &nbsp;No legal support can be claimed for Power of Attorney
                    signed by a notary public.
                  </li>
                </ul>
                <h5>Agreement</h5>
                <ul>
                  <li>
                    &nbsp;Once all the matters, financial/otherwise are settled
                    between the parties, it is better to give an advance and
                    write an agreement.
                  </li>
                  <li>
                    &nbsp;This is to ensure there is no change in dealings.
                  </li>
                  <li>
                    &nbsp;The agreement should be written in Rs.50 stamp paper.
                  </li>
                  <li>
                    &nbsp;The agreement should state the actual cost, the
                    advance amount, the time span within which the actual sale
                    should take place and how to proceed in case of any default
                    from either parties, to cover the loss.
                  </li>
                  <li>
                    &nbsp;The agreement can be prepared by a lawyer and should
                    be signed by both the parties and two witnesses. After
                    signing the agreement if one of the parties makes a default,
                    the other party can take legal action against him.
                  </li>
                </ul>
                <h5>Registration</h5>
                <ul>
                  <li>
                    &nbsp;The land can be registered in a sub registrar office,
                    after preparing the title deed and other needed information.
                  </li>
                  <li>
                    &nbsp;The title deed can be hand written by a government
                    licensed document writer.
                  </li>
                  <li>
                    &nbsp;Even lawyers can prepare the deed, but the document
                    can only be computer printed or typed, not hand written.
                  </li>
                  <li>
                    &nbsp;A draft should be prepared before actually committing
                    the document to stamp paper.
                  </li>
                  <li>
                    &nbsp;Make sure all the details mentioned are accurate. If
                    there is incorrectness in the document after registering, a
                    secondary document with the correct details has to be
                    registered and depending on the incorrectness, the
                    registration expenses will be repeated.
                  </li>
                  <li>
                    &nbsp;The deed should be registered within the time limit
                    mentioned in the agreement.
                  </li>
                  <li>
                    &nbsp;Original title deed, previous deeds, Property/House
                    Tax receipts, Torrance Plan (optional) etc. Plus two
                    witnesses are needed for registering the property.
                  </li>
                  <li>
                    &nbsp;For land costing more than Rupees five lakhs, the
                    seller should submit either his Pan Card or Form Number 16
                    during registration.
                  </li>
                  <li>
                    &nbsp;The expenses involved during registration include
                    stamp duty, registration fees, document writers/ lawyer’s
                    fees etc.
                  </li>
                  <li>
                    &nbsp;The stamp duty will depend on the cost of the property
                    and varies from Municipality to Corporation to Panchayat.
                  </li>
                  <li>
                    &nbsp;Stamp Duty – In Panchayat it is 4% of the cost of the
                    land In Municipality it is 5% In Corporation it is 6%.
                  </li>
                  <li>
                    &nbsp;Two percentages will be charged as the registration
                    fees. Document writer’s fees also depend on the cost of the
                    property and vary with individuals. There is a percentage
                    prescribed by the government as document writer’s fee and
                    they cannot charge more than the prescribed limit.
                  </li>
                  <li>
                    &nbsp;After registration, the registered document will be
                    received after 2-3 weeks, from the registrar office.
                  </li>
                </ul>
                <h5>Changing the title in Village office</h5>
                <ul>
                  <li>
                    &nbsp;The whole legal procedure of buying the property will
                    be complete only if the new owners name is added in the
                    village office records.
                  </li>
                  <li>
                    &nbsp;An application can be made along with the copy of the
                    registered deed to the village office to get this done.
                  </li>
                </ul>
                <h2>NRI Guidelines</h2>
                <h5>
                  Guidelines for NRI * and PIOs* for acquisition and transfer of
                  Residential and Commercial Property in India
                </h5>
                <p>I. Acquisition of Immovable Property in India.</p>
                <p>II. Transfer of immovable property in India.</p>
                <p>III. Mode of payment for purchase.</p>
                <p>
                  IV. Repatriation of sale proceeds of residential / commercial
                  property purchased by NRI / PIO.
                </p>
                <h5>I. Acquisition of Immovable Property in India.</h5>
                <h5>
                  Who can purchase residential or commercial property in India?
                </h5>
                <p>
                  Under the general permission available, the following
                  categories can freely purchase residential or commercial
                  property in India.
                </p>
                <p>
                  i) Non-Resident Indian (NRI) – that is a citizen of India who
                  stays abroad for employment / carrying on business or vocation
                  outside India or stays abroad under circumstances indicating
                  an intention for an uncertain duration of stay abroad.
                </p>
                <p>
                  ii) Person of Indian Origin (PIO) – that is an individual (not
                  being a citizen of Pakistan or Bangladesh or Sri Lanka or
                  Afghanistan or China or Iran or Nepal or Bhutan), who
                </p>
                <p>1. At any time, held Indian passport, or</p>
                <p>
                  2. Who or either of whose father or grandfather was a citizen
                  of India by virtue of the Constitution of India or the
                  Citizenship Act, 1955 (57 of 1955).
                </p>
                <p>
                  Note: The general permission, however, does not cover purchase
                  of agricultural land / plantation property / farmhouse in
                  India. Such proposals will require specific approval of
                  Reserve Bank and the proposals are considered in consultation
                  with the Government of India.
                </p>
                <h5>
                  Do any documents need to be filed with Reserve Bank of India
                  after purchase?
                </h5>
                <p>
                  No. An NRI / PIO who has purchased residential / commercial
                  property under general permission, is not required to file any
                  documents with the Reserve Bank.
                </p>
                <h5>
                  How many residential / commercial properties can NRI / PIO
                  purchase under the general permission?
                </h5>
                <p>
                  There are no restrictions on the number of residential /
                  commercial properties that can be purchased.
                </p>
                <h5>
                  Can a foreign national of non-Indian origin be a second holder
                  to immovable property purchased by NRI / PIO?
                </h5>
                <p>No.</p>
                <h5>
                  Can a foreign national of non-Indian origin resident outside
                  India purchase immovable property in India?
                </h5>
                <p>
                  No. A foreign national of non-Indian origin, resident outside
                  India cannot purchase any immovable property in India. But,
                  he/she may take residential accommodation on lease provided
                  the period of lease does not exceed five years. In such cases,
                  there is no requirement of taking any permission of or
                  reporting to Reserve Bank.
                </p>
                <h5>
                  Can a foreign national who is a person resident in India
                  purchase immovable property in India?
                </h5>
                <p>
                  Yes, but the person concerned would have to obtain the
                  approvals, and fulfill the requirements if any, prescribed by
                  other authorities, such as the concerned State Government, etc
                  However, a foreign national resident in India who is a citizen
                  of Pakistan, Bangladesh, Sri Lanka, Afghanistan, China, Iran,
                  Nepal and Bhutan would require prior approval of Reserve Bank.
                  Such requests are considered by Reserve Bank in consultation
                  with the Government of India.
                </p>
                <h5>
                  Can an office of a foreign company purchase immovable property
                  in India?
                </h5>
                <p>
                  A foreign company which has established a Branch Office or
                  other place of business in India, in accordance with FERA /
                  FEMA regulations, can acquire any immovable property in India,
                  which is necessary for or incidental to carrying on such
                  activity. The payment for acquiring such a property should be
                  made by way of foreign inward remittance through proper
                  banking channel. A declaration in form IPI should be filed
                  with Reserve Bank within ninety days from the date of
                  acquiring the property. Such a property can also be mortgage
                  with an Authorized Dealer as a security for other borrowings.
                  On winding up of the business, the sale proceeds of such
                  property can be repatriated only with the prior approval of
                  Reserve Bank. Further, acquisition of immovable property by
                  entities who had set up Branch Offices in India and
                  incorporated in Pakistan, Bangladesh, Sri Lanka, Afghanistan,
                  China, Iran, Nepal and Bhutan would require prior approval of
                  Reserve Bank to acquire such immovable property. However, if
                  the foreign company has established a Liaison Office, it
                  cannot acquire immovable property. In such cases, Liaison
                  Offices can take property by way of lease not exceeding 5
                  years.
                </p>
                <h5>
                  Whether immovable property in India can be acquired by way of
                  gift?
                </h5>
                <p>
                  Yes, NRIs and PIOs can freely acquire immovable property by
                  way of gift either from
                </p>
                <p>i) a person resident in India or</p>
                <p>ii) an NRI or</p>
                <p>iii) a PIO.</p>
                <p>
                  However, the property can only be commercial or residential.
                  Agricultural land / plantation property / farmhouse in India
                  cannot be acquired by way of gift.
                </p>
                <p>
                  A foreign national of non-Indian origin resident outside India
                  cannot acquire any immovable property in India through gift.
                </p>
                <h5>
                  Whether a non-resident can inherit immovable property in
                  India?
                </h5>
                <p>Yes, a person resident outside India i.e.</p>
                <p>i) an NRI</p>
                <p>Tii) a PIO and</p>
                <p>
                  iii) a foreign national of non-Indian origin can inherit and
                  hold immovable property in India from a person who was
                  resident in India. However, a citizen of Pakistan, Bangladesh,
                  Sri Lanka, Afghanistan, China, Iran, Nepal and Bhutan should
                  seek specific approval of Reserve Bank.
                </p>
                <h5>
                  From whom can the non-resident inherit immovable property?
                </h5>
                <p>
                  A person resident outside India (i.e. NRI or PIO or foreign
                  national of non-Indian origin) can inherit immovable property
                  from
                </p>
                <p>(a) a person resident in India.</p>
                <p>(b) a person resident outside India.</p>
                <p>
                  However, the person from whom the property is inherited should
                  have acquired the same in accordance with the foreign exchange
                  regulations applicable at that point of time.
                </p>
                <p>II. Transfer of immovable property in India.</p>
                <p>Transfer by Sale</p>
                <h5>
                  Can an NRI / PIO/foreign national sell his residential /
                  commercial property?
                </h5>
                <p>NRI can sell property in India to</p>
                <p>i) a person resident in India or</p>
                <p>ii) an NRI or</p>
                <p>iii) a PIO</p>
                <p>PIO can sell property in India to</p>
                <p>i) a person resident in India.</p>
                <p>ii) An NRI or</p>
                <p>iii) a PIO – with the prior approval of Reserve Bank</p>
                <p>
                  Foreign national of non-Indian origin including a citizen of
                  Pakistan or Bangladesh or Sri Lanka or Afghanistan or China or
                  Iran or Nepal or Bhutan can sell property in India with prior
                  approval of Reserve Bank to
                </p>
                <p>i) a person resident in India</p>
                <p>ii) an NRI</p>
                <p>iii) a PIO</p>
                <h5>
                  Can an agricultural land / plantation property / farm house in
                  India owned / held by a non-resident be sold?
                </h5>
                <p>
                  a) NRI / PIO may sell agricultural land /plantation
                  property/farm house to a person resident in India who is a
                  citizen of India.
                </p>
                <p>
                  (b) Foreign national of non-Indian origin resident outside
                  India would need prior approval of Reserve Bank to sell
                  agricultural land/plantation property / farm house in India
                </p>
                <p>Transfer by gift</p>
                <h5>
                  Can a non-resident gift his residential / commercial property?
                </h5>
                <p>Yes.</p>
                <p>
                  (a) NRI / PIO may gift residential / commercial property to-
                </p>
                <p>(i) person resident in India or</p>
                <p>(ii) an NRI or</p>
                <p>(iii) PIO.</p>
                <p>
                  (b) Foreign national of non-Indian origin needs prior approval
                  of Reserve Bank
                </p>
                <p>
                  Can an NRI / PIO / Foreign national holding an agricultural
                  land / plantation property / farmhouse in India gift the same?
                </p>
                <p>
                  (a) NRI / PIO can gift but only to a person resident in India
                  who is a citizen of India.
                </p>
                <p>
                  (b) Foreign national of non-Indian origin needs prior approval
                  of Reserve Bank
                </p>
                <p>Transfer through mortgage</p>
                <h5>Can residential / commercial property be mortgaged?</h5>
                <p>i) NRI / PIO can mortgage to:</p>
                <p>
                  (a) an authorized dealer / housing finance institution in
                  India –without the approval of Reserve Bank.
                </p>
                <p>(b) a party abroad – with prior approval of Reserve Bank.</p>
                <p>
                  ii) a foreign national of non-Indian origin can mortgage only
                  with prior approval of Reserve Bank.
                </p>
                <p>
                  iii) a foreign company which has established a Branch Office
                  or other place of business in accordance with FERA / FEMA
                  regulations has general permission to mortgage the property
                  with an authorized dealer in India.
                </p>
                <p>IV. Mode of payment for purchase.</p>
                <h5>
                  How can an NRI / PIO make payment for purchase of residential
                  / commercial property in India?
                </h5>
                <p>Payment can be made by NRI / PIO out of</p>
                <p>
                  (a) funds remitted to India through normal banking channel or
                </p>
                <p>
                  (b) funds held in NRE / FCNR (B) / NRO account maintained in
                  India.
                </p>
                <p>
                  No payment can be made either by traveler’s cheque or by
                  foreign currency notes.
                </p>
                <p>No payment can be made outside India.</p>
                <h5>
                  What shall be the option if there is refund of application
                  money / payment made by the building agencies / seller because
                  of non-allotment of flat / plot / cancellation of bookings /
                  contracts?
                </h5>
                <p>
                  The amount of refund, together with interest (net of income
                  tax) can be credited to NRE account. This is subject to
                  condition that the original payment was made by way of inward
                  remittance or by debit to NRE / FCNR (B) account. (Please
                  refer to A.P. (DIR) Series Circular No. 46 dated 12.11.2002)
                </p>
                <h5>
                  Can NRI / PIO avail of loan from an authorized dealer for
                  acquiring flat / house in India for his own residential use
                  against the security of funds held in his NRE Fixed Deposit
                  account / FCNR (B) account?
                </h5>
                <p>
                  Yes, such loans are subject to the terms and conditions as
                  laid down in Schedules 1 and 2 to Notification No. FEMA
                  5/2000-RB dated May 3, 2000 as amended from time to time.
                  However, banks cannot grant fresh loans or renew existing
                  loans in excess of Rupees 20 lakh against NRE and FCNR (B)
                  deposits either to the depositors or to third parties [cf.
                  A.P. (DIR Series) Circular No. 29 dated January 31, 2007].
                </p>
                <p>Such loans can be repaid</p>
                <p>
                  (a) by way of inward remittance through normal banking channel
                  or
                </p>
                <p>(b) by debit to his NRE / FCNR (B) / NRO account or</p>
                <p>(c) out of rental income from such property.</p>
                <p>
                  (d) by the borrower’s close relatives, as defined in section 6
                  of the Companies Act, 1956, through their account in India by
                  crediting the borrower’s loan account.
                </p>
                <p>Repatriation</p>
                <p>
                  (a). In case the amount has been received from inward
                  remittance or debit to NRE/FCNR(B)/NRO account for acquiring
                  the property or for repayment of the loan, the principal
                  amount can be repatriated outside India.
                </p>
                <p>
                  For this purpose, repatriation outside India means the buying
                  or drawing of foreign exchange from an authorized dealer in
                  India and remitting it outside India through normal banking
                  channels or crediting it to an account denominated in foreign
                  currency or to an account in Indian currency maintained with
                  an authorized dealer from which it can be converted in foreign
                  currency
                </p>
                <p>
                  (b) in case the property is acquired out of Rupee resources
                  and/or the loan is repaid by close relatives in India (as
                  defined in Section 6 of the Companies Act, 1956), the amount
                  can be credited to the NRO account of the NRI/PIO. The amount
                  of capital gains, if any, arising out of sale of the property
                  can also be credited to the NRO account.
                </p>
                <p>
                  NRI/PIO are also allowed by the Authorized Dealers to
                  repatriate an amount up to USD 1 million per financial year
                  out of the balance in the NRO account for all bonafide
                  purposes to the satisfaction of the authorised dealers,
                  subject to tax compliance.
                </p>
                <h5>
                  Can NRI / PIO, avail of housing loan in rupees from an
                  authorised dealer or housing finance institution in India
                  approved by the National Housing Bank for purchase of
                  residential accommodation or for the purpose of repairs /
                  renovation / improvement of residential accommodation ? How
                  can such loan be repaid?
                </h5>
                <p>
                  Yes, NRI/PIO can avail of housing loan in rupees from an
                  Authorised Dealer or housing finance institution subject to
                  certain terms and conditions. (Please refer to Regulation 8 of
                  Notification No. FEMA 4/2000-RB dated 3.5.2000 and A.P. (DIR)
                  Series Circular No. 95 dated April 26, 2003).
                  <br />
                  Such a loan can be repaid
                </p>
                <p>
                  (a) by way of inward remittance through normal banking channel
                  or
                </p>
                <p>(b) by debit to his NRE / FCNR (B) / NRO account or</p>
                <p>(c) out of rental income from such property.</p>
                <p>
                  (d) by the borrower’s close relatives, as defined in section 6
                  of the Companies Act, 1956, through their account in India by
                  crediting the borrower’s loan account.
                </p>
                <h5>
                  Can NRI/PIO avail of housing loan in rupees from his employer
                  in India?
                </h5>
                <p>
                  Yes, subject to certain terms and conditions (Please refer to
                  Regulation 8A of Notification No. FEMA 4/2000-RB dated May 3,
                  2000 and A.P. (DIR Series) Circular No.27 dated October 10,
                  2003).
                </p>
                <p>
                  IV. Repatriation of sale proceeds of residential / commercial
                  property purchased by NRI / PIO
                </p>
                <h5>
                  Can NRI / PIO repatriate the sale proceeds of immovable
                  property? If so, what are the terms?
                </h5>
                <p>
                  NRI / PIO may repatriate the sale proceeds of immovable
                  property in India
                </p>
                <p>
                  (a) If the property was acquired out of foreign exchange
                  sources i.e. remitted through normal banking channels / by
                  debit to NRE / FCNR (B) account
                </p>
                <p>
                  The amount to be repatriated should not exceed the amount paid
                  for the property:
                </p>
                <p>
                  1. in foreign exchange received through normal banking channel
                  or
                </p>
                <p>
                  2. by debit to NRE account(foreign currency equivalent, as on
                  the date of payment) or debit to FCNR (B) account.
                </p>
                <p>
                  Repatriation of sale proceeds of residential property
                  purchased by NRI / PIO out of foreign exchange is restricted
                  to not more than two such properties. Capital gains, if any,
                  may be credited to the NRO account from where the NRI/PIO may
                  repatriate an amount up to USD one million, per financial
                  year, as discussed below.
                </p>
                <p>
                  (b) If the property was acquired out of Rupee sources, NRI or
                  PIO may remit an amount up to USD one million, per financial
                  year, out of the balances held in the NRO account (inclusive
                  of sale proceeds of assets acquired by way of inheritance or
                  settlement), for all the bonafide purposes to the satisfaction
                  of the Authorized Dealer bank and subject to tax compliance.
                </p>
                <h5>
                  Can an NRI/PIO repatriate the proceeds in case the sale
                  proceed was deposited in NRO account?
                </h5>
                <p>
                  From the NRO account, NRI/PIO may repatriate up to USD one
                  million per financial year (April-March), which would also
                  include the sale proceeds of immovable property.
                </p>
                <h5>
                  If a Rupee loan was taken by NRI/PIO from Authorised Dealer or
                  housing finance institution for purchase of residential
                  property can an NRI / PIO repatriate the sale proceeds of such
                  property?
                </h5>
                <p>
                  Yes, provided the loan has been subsequently repaid by
                  remitting funds from abroad or by debit to NRE/FCNR(B)
                  accounts (Please see A.P. (DIR) Series Circular No. 101 dated
                  5.5.2003)
                </p>
                <h5>
                  If the property was purchased from foreign inward remittance
                  or from NRE / FCNR (B) account, can the sale proceeds of
                  property be repatriated immediately?
                </h5>
                <p>Yes,</p>
                <h5>
                  Is there any restriction on number of residential properties
                  in respect of which sale proceeds can be repatriated by NRI /
                  PIO?
                </h5>
                <p>
                  Yes, sale proceeds of not more than two residential properties
                  can be repatriated.
                </p>
                <h5>
                  If the immovable property was acquired by way of gift by the
                  NRI/PIO, can he repatriate abroad the funds from sale?
                </h5>
                <p>
                  The sale proceeds of immovable property acquired by way of
                  gift should be credited to NRO account only. From the balance
                  in the NRO account, NRI/PIO may remit up to USD one million,
                  per financial year, subject to the satisfaction of Authorised
                  Dealer and payment of applicable taxes.
                </p>
                <h5>
                  If the immovable property was received as inheritance by the
                  NRI/PIO can he repatriate the sale proceeds?
                </h5>
                <p>
                  Yes, general permission is available to the NRIs/PIO to
                  repatriate the sale proceeds of the immovable property
                  inherited from a person resident in India. NRIs/PIO may
                  repatriate an amount not exceeding USD one million, per
                  financial year, on production of documentary evidence in
                  support of acquisition / inheritance of assets, an undertaking
                  by the remitter and certificate by a Chartered Accountant in
                  the formats prescribed by the Central Board of Direct Taxes
                  vide their Circular No.10/2002 dated October 9, 2002. [cf. A.
                  P. (DIR Series) Circular No.56 dated November 26, 2002].
                </p>
                <p>
                  In case of a foreign national, sale proceeds can also be
                  repatriated even if the property is inherited from a person
                  resident outside India. But this is allowed only with prior
                  approval of Reserve Bank. The foreign national has to approach
                  Reserve Bank with documentary evidence in support of
                  inheritance of the immovable property and the undertaking and
                  the C.A. Certificate as mentioned above.
                </p>
                <p>
                  The general permission for repatriation of sale proceeds of
                  immovable property is not available to a citizen of Pakistan,
                  Bangladesh, Sri Lanka, China, Afghanistan and Iran and he has
                  to seek specific approval of Reserve Bank.
                </p>
                <p>
                  As FEMA specifically permits transactions only in Indian
                  Rupees with citizens of Nepal and Bhutan, the question of
                  repatriation of the sale proceeds in foreign exchange to Nepal
                  and Bhutan would not arise.
                </p>
                <h2>Tax Benefits</h2>
                <h5>Avail tax incentives</h5>
                <p>
                  Did you know that tax laws assure incentives to encourage
                  people to buy homes? Go on. Check out these incentives and
                  make the most of them.
                </p>
                <h5>Incentive 1 :</h5>
                <p>
                  Interest on housing loans are exempted upto a ceiling of
                  Rs.1,50,000 (sec 24).
                </p>
                <h5>Incentive 2 :</h5>
                <p>
                  A Deduction of Rs.1 Lakh from taxable income is available for
                  home loan principle re-payment.
                </p>
                <h5>Incentive 3 :</h5>
                <p>
                  Exemption from Capital Gains (sec 54f) : Capital gains
                  resulting from the transfer of any capital asset not being a
                  residential house, will be exempt of the net consideration is
                  used to purchase a residential house within a period of 1 year
                  before or 2 years after the date of transfer or construct a
                  residential house within 3 years after the date of transfer.
                </p>
                <h5>
                  Some guidelines for working professionals to plan their loans.
                </h5>
                <p>
                  The ideal loan an investor should avail of is Rs.20 lakh.
                  Here’s how…
                </p>
                <p>
                  Let’s presume that a senior executive receiving a salary of
                  Rs.5 lakh per annum takes a loan of Rs.20 lakh. The rate of
                  interest charged on this loan is 7.5% per annum. Thus the
                  total interest payable for one year on Rs.20 lakh comes to
                  only Rs.1,50,000.
                </p>
                <p>
                  The maximum amount of interest that will be allowed as a
                  deduction while computing the income of the individual from
                  all sources taken together is restricted to a maximum sum of
                  Rs.1,50,000/- per annum. So, the entire interest payment of
                  say Rs.1,50,000/- would be allowed as a deduction to this
                  executive from his salary income of Rs.5 lakh!.
                </p>
                <p>
                  This implies that the executive would be saving income tax on
                  Rs.1,50,000/- which is the payable interest. The tax saving
                  would be at the rate of 33% being the maximum marginal rate of
                  income tax applicable to him. Thus, the saving on account of
                  deduction of interest from the total income for salaried
                  executive comes to Rs.49,500.
                </p>
                <p>
                  Hence, we find that from the total interest payment of
                  Rs.1,50,000/- payable by the executive on loan of Rs.20 lakh @
                  7.5 % interest has actually been paid by the income tax
                  department by granting him full deduction in respect of such
                  interest payment on loan.
                </p>
                <p>
                  There by the net outgo of interest on the loan comes to
                  Rs.1,00,500/- (Rs.1,50,000 – Rs.49,500). Thus the net impact
                  of interest on the executive’s loan of Rs.20 lakh comes to
                  Rs.1,00,500/-. This in fact, is just 5.02% rate of interest
                  per annum.
                </p>
                <p>
                  If we go a step further and take into consideration the impact
                  of tax saving as a result of the deduction from taxable income
                  on account of home loan principal repayment. We find that the
                  said executive is able to save income tax to the tune of
                  Rs.30,000/-.
                </p>
                <p>
                  Hence from the above amount of Rs 1,00,500/- if we deduct
                  Rs.30,000/- the net impact comes to a mere Rs 70,500 –
                  Rs.1,50,000 interest Rs.49,500/- tax saving on interest
                  payment Rs.30,000/- cash flow saving as a result of principal
                  repayment.
                </p>
                <p>
                  Thus, on a total loan of Rs.20 lakh, the net impact of out
                  flow after tax comes to Rs.70,500/- which means effective
                  interest rate of merely 3.525% in respect of a Rs.20 lakh
                  loan.
                </p>
                <p>
                  So keep in mind the golden Rs.20 lakh mark and you will reap
                  rich benefits.
                </p>
                <h5>Disclaimer: E-Brochure, Website Images and Videos for Illustrative Purposes Only</h5>
                <p>"The e-brochure,images and videos in the website are purely conceptual. The company reserves the right to change, modify any or all the contents herein at its discretion, without prior notice.</p>
                <p>Note: Values of the saleable area in the e-brochure and floor plan images in the website may slightly vary in the actual project."</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Terms;
